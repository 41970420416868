import React from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { compressToEncodedURIComponent } from "lz-string";
import Footer from "./Footer";

const Ticket = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const visit = query.get("visit");
  const game = query.get("game");
  const score = query.get("score");
  const code = query.get("code");
  const b = query.get("b");

  const ticketData = { visit, game, score, code, b };
  const ticketNumber = compressToEncodedURIComponent(
    JSON.stringify(ticketData)
  );

  const handleWhatsApp = () => {
    const message = `我獲得了積分卷：${ticketNumber}`;
    const whatsappURL = `https://wa.me/85246359206?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <div style={{ flex: "1", textAlign: "center" }}>
        <h1 style={{ fontSize: "1.5rem", margin: "20px 0" }}>積分卷</h1>
        <Button
          variant="contained"
          color="primary"
          onClick={handleWhatsApp}
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "0 auto",
            fontSize: "1rem",
            padding: "10px 0",
          }}
        >
          點擊通過 WhatsApp 提交積分卷
        </Button>
        <p style={{ marginTop: "10px", fontSize: "0.9rem", color: "#555" }}>
          如遇任何問題，請WhatsApp 46359206
        </p>
        <textarea
          readOnly
          value={ticketNumber}
          style={{
            width: "100%",
            maxWidth: "300px",
            height: "100px",
            margin: "10px auto",
            fontSize: "0.9rem",
            padding: "10px",
            boxSizing: "border-box",
            borderColor: "#ccc",
            borderRadius: "5px",
            resize: "none",
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Ticket;
