import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getIslandsFromCookies, setIslandsToCookies } from "./cookieUtils";
import GameIslandCard from "./GameIslandCard";
import ScoreRegisterDialog from "./ScoreRegisterDialog";
import { decompressFromEncodedURIComponent } from "lz-string";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";

const Wallet = () => {
  const [islands, setIslands] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const location = useLocation();
  const [ticketInput, setTicketInput] = useState("");

  useEffect(() => {
    const existingIslands = getIslandsFromCookies();
    setIslands(existingIslands);
  }, []);

  const handleRegister = (newRecord) => {
    const existingIslands = getIslandsFromCookies();
    const islandIndex = existingIslands.findIndex(
      (island) => island.name === newRecord.visit
    );

    if (islandIndex === -1) {
      // New island
      const newIsland = {
        name: newRecord.visit,
        records: [
          {
            game: newRecord.game,
            score: parseInt(newRecord.score, 10), // Ensure score is treated as a number
            code: newRecord.code,
            status: "未登記",
          },
        ],
      };
      existingIslands.push(newIsland);
    } else {
      // Existing island
      const existingIsland = existingIslands[islandIndex];
      if (!existingIsland.records) {
        existingIsland.records = [];
      }
      const recordIndex = existingIsland.records.findIndex(
        (record) =>
          record.game === newRecord.game && record.code === newRecord.code
      );
      if (recordIndex === -1) {
        // New record
        existingIsland.records.push({
          game: newRecord.game,
          score: parseInt(newRecord.score, 10), // Ensure score is treated as a number
          code: newRecord.code,
          status: "未登記",
        });
      } else {
        // Duplicate record
        toast.error("重複紀錄!");
        return;
      }
    }

    setIslandsToCookies(existingIslands);
    setIslands(existingIslands);
    setShowDialog(false);
    toast.success("積分卷成功登記!");
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const visit = query.get("visit");
    const game = query.get("game");
    const score = parseInt(query.get("score"), 10);
    const code = query.get("code");

    if (visit && game && !isNaN(score) && code) {
      const existingIslands = getIslandsFromCookies();
      const islandIndex = existingIslands.findIndex(
        (island) => island.name === visit
      );

      if (islandIndex !== -1) {
        const existingIsland = existingIslands[islandIndex];
        if (existingIsland.records) {
          const recordIndex = existingIsland.records.findIndex(
            (record) => record.game === game && record.code === code
          );
          if (recordIndex !== -1) {
            // Record already exists, do not show the dialog
            return;
          }
        }
      }

      setCurrentRecord({ visit, game, score, code });
      setShowDialog(true);
    }
  }, [location]);

  const handleTicketInput = () => {
    try {
      const input = ticketInput.trim();
      const ticketData = input.startsWith("我獲得了積分卷：")
        ? input.replace("我獲得了積分卷：", "")
        : input;
      const decompressedData = decompressFromEncodedURIComponent(ticketData);
      if (decompressedData) {
        const { visit, game, score, code } = JSON.parse(decompressedData);
        handleRegister({ visit, game, score: parseInt(score, 10), code });
      } else {
        toast.error("無效的積分卷數據!");
      }
    } catch (error) {
      console.error("解析積分卷數據失敗:", error);
      toast.error("無效的積分卷數據!");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <div style={{ flex: "1", textAlign: "center" }}>
        <h1 style={{ fontSize: "1.5rem", margin: "20px 0" }}>我的積分錢包</h1>
        <TextField
          label="貼上積分卷"
          placeholder="我獲得了積分卷：xxxxx"
          variant="outlined"
          fullWidth
          value={ticketInput}
          onChange={(e) => setTicketInput(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleTicketInput}
          style={{
            width: "100%",
            maxWidth: "300px",
            margin: "0 auto",
            fontSize: "1rem",
            padding: "10px 0",
          }}
        >
          兌換積分卷
        </Button>
        <div style={{ marginTop: "20px" }}>
          {islands.map((island, index) => {
            const totalScore = island.records.reduce(
              (sum, record) => sum + record.score,
              0
            );
            return (
              <GameIslandCard
                key={index}
                island={island}
                totalScore={totalScore}
              />
            );
          })}
        </div>
        {showDialog && currentRecord && (
          <ScoreRegisterDialog
            record={currentRecord}
            onRegister={handleRegister}
            onClose={() => setShowDialog(false)}
            islands={islands}
          />
        )}
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Wallet;
