import React, { useEffect, useState } from "react";
import { getIslandsFromCookies, setIslandsToCookies } from "./cookieUtils";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import items from "./items.json";
import Footer from "./Footer";

const Shop = () => {
  const [islands, setIslands] = useState([]);

  useEffect(() => {
    const existingIslands = getIslandsFromCookies();
    setIslands(existingIslands);
  }, []);

  const handleRedeem = (islandName, itemName, itemScore) => {
    const island = islands.find((island) => island.name === islandName);
    if (island) {
      const totalScore = island.records.reduce(
        (sum, record) => sum + record.score,
        0
      );
      if (totalScore >= itemScore) {
        if (window.confirm(`Are you sure you want to redeem ${itemName}?`)) {
          const existingIslands = [...islands];
          const islandIndex = existingIslands.findIndex(
            (island) => island.name === islandName
          );
          if (islandIndex !== -1) {
            existingIslands[islandIndex].records.push({
              game: itemName,
              score: -itemScore,
              code: "redeem",
              status: "已兌換",
            });
            setIslandsToCookies(existingIslands);
            setIslands(existingIslands);
            toast.success(`Successfully redeemed ${itemName}`);

            // Send WhatsApp message
            const message = `我要兌換：${itemName}`;
            const whatsappURL = `https://wa.me/85246359206?text=${encodeURIComponent(
              message
            )}`;
            window.open(whatsappURL, "_blank");
          }
        }
      } else {
        toast.error("Not enough score to redeem this item!");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <div style={{ flex: "1", textAlign: "center" }}>
        <h1 style={{ fontSize: "1.5rem", margin: "20px 0" }}>兌換商店</h1>
        {items.islands.map((island, index) => {
          const islandData = islands.find((i) => i.name === island.name);
          const totalScore = islandData
            ? islandData.records.reduce((sum, record) => sum + record.score, 0)
            : 0;

          return (
            <Card key={index} sx={{ minWidth: 275, margin: 2, boxShadow: 3 }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "flex-start", sm: "center" },
                  }}
                >
                  {island.name}
                  <span style={{ marginTop: { xs: "10px", sm: "0" } }}>
                    總積分: {totalScore}
                  </span>
                </Typography>
                {island.sets.map((set, idx) => (
                  <div
                    key={idx}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "10px",
                      margin: "10px 0",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Typography variant="h6">{set.name}</Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {set.content}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      所需積分: {set.score}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleRedeem(island.name, set.name, set.score)
                      }
                      disabled={totalScore < set.score}
                      sx={{
                        marginTop: "10px",
                        width: "100%",
                        fontSize: "1rem",
                      }}
                    >
                      兌換
                    </Button>
                  </div>
                ))}
              </CardContent>
            </Card>
          );
        })}
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Shop;
