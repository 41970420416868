// cookieUtils.js
import Cookies from "js-cookie";

const getIslandsFromCookies = () => {
  return JSON.parse(Cookies.get("islands") || "[]");
};

const setIslandsToCookies = (islands) => {
  Cookies.set("islands", JSON.stringify(islands), {
    expires: 7,
    path: "/",
  });
};

export { getIslandsFromCookies, setIslandsToCookies };
