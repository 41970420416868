import React from "react";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ClearCookies = () => {
  const navigate = useNavigate();

  const handleClearCookies = () => {
    // Get all cookies and delete them
    const allCookies = Cookies.get();
    for (const cookie in allCookies) {
      Cookies.remove(cookie, { path: "/" });
    }
    toast.success("All cookies have been deleted successfully!");
    setTimeout(() => {
      navigate(-1); // Redirect back to the previous page
    }, 2000); // Redirect after showing the success message for 2 seconds
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Clear Cookies</h1>
      <Button variant="contained" onClick={() => navigate(-1)}>
        Back
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClearCookies}
        style={{ marginTop: "20px" }}
      >
        Delete All Cookies
      </Button>
      <ToastContainer />
    </div>
  );
};

export default ClearCookies;
