import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Ticket from "./Ticket";
import Wallet from "./Wallet";
import AdminWallet from "./AdminWallet";
import Shop from "./Shop";
import ClearCookies from "./ClearCookies"; // Import the ClearCookies component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/admin-wallet" element={<AdminWallet />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/clear-cookies" element={<ClearCookies />} />{" "}
        {/* Add new route */}
        <Route path="/" element={<Wallet />} /> {/* Default route */}
      </Routes>
    </Router>
  );
};

export default App;
