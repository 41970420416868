import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme, useMediaQuery } from "@mui/material";

const GameIslandCard = ({ island }) => {
  // Ensure records is an array
  const records = island.records || [];
  // Calculate total score
  const totalScore = records.reduce((sum, record) => sum + record.score, 0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to map game names
  const getGameName = (game) => {
    switch (game) {
      case "chill-fishing":
        return "輕鬆捉魚魚";
      case "kicking-hexagon":
        return "彈跳小冬甩";
      case "running-jumper":
        return "大戰仙人掌";
      default:
        return game;
    }
  };

  return (
    <Card sx={{ minWidth: 275, margin: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {island.name}
          <span>總積分: {totalScore}</span>
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>任務</TableCell>
              <TableCell>積分</TableCell>
              <TableCell>遊玩碼</TableCell>
              {/* <TableCell>狀態</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <TableRow key={index}>
                <TableCell>{getGameName(record.game)}</TableCell>
                <TableCell>{record.score}</TableCell>
                <TableCell>{record.code}</TableCell>
                {/* <TableCell>{record.status}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default GameIslandCard;
