import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "#f5f5f5",
        textAlign: "center",
        fontSize: "0.9rem", // Smaller font size for better readability on mobile
      }}
    >
      <Typography variant="body2" align="center" sx={{ mb: 1 }}>
        My comBOMeow 自助平台只作積分整理參考
      </Typography>
      <Typography variant="body2" align="center">
        Showroom 一切積分及兌換以Whatsapp人手記錄作準
      </Typography>
    </Box>
  );
};

export default Footer;
