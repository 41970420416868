import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ScoreRegisterDialog = ({ record, onRegister, onClose }) => {
  const handleRegister = () => {
    // Construct the WhatsApp URL
    const whatsappURL = `https://wa.me/85246359206?text=${encodeURIComponent(
      `冒險島: ${record.visit}\n任務: ${record.game}\n積分: ${record.score}\n驗證碼: ${record.code}`
    )}`;

    // Open WhatsApp URL in a new tab
    window.open(whatsappURL, "_blank");

    // Call the onRegister function to update the cookies
    onRegister(record);
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>積分登記</DialogTitle>
      <DialogContent>
        <DialogContentText>
          冒險島: {record.visit}
          <br />
          任務: {record.game}
          <br />
          所獲積分: {record.score}
        </DialogContentText>
        <div style={{ marginTop: "20px", color: "grey", fontSize: "12px" }}>
          登記積分步驟暫時以人手處理，恕未能即時跟進
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRegister} color="primary">
          跳轉到Whatsapp 進行積分登記
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScoreRegisterDialog;
